import React, { useState, useEffect, memo } from "react";
import { Modal, TextField, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import apiRequest from "../../../../../utils/api.js";
import CommonButton from "../../../../common/CommonButton.js";
import * as Yup from "yup";
import CreateOrUpdateWalletSettingsConfig from "../../../../../formSchema/CreateOrUpdateWalletSettingsConfig.js";
import { useDispatch } from 'react-redux';
import { setErrorMessage } from "../../../../../redux/actions/commonAction.js";

const CreateOrUpdateWalletSettings = memo((props) => {
    const dispatch = useDispatch();
    const [textInput, setTextInput] = useState({
        blockchain: "",
        network: "",
        minimumWallets: 0,
        maximumWallets: 1,
        minCapacityThreshold: 0,
        maxCapacityThreshold: 0,
    });
    const [error, setError] = useState({});
    const walletSettingsFields = [
        { name: "minimumWallets", label: "Min. Wallets" },
        { name: "maximumWallets", label: "Max. Wallets" },
        { name: "minCapacityThreshold", label: "Min. Capacity Threshold" },
        { name: "maxCapacityThreshold", label: "Max. Capacity Threshold" },
    ]

    const uniqueBlockchains = [...new Set(props?.cryptos?.map((crypto) => crypto.blockchain))];

    // Utility function to check if an object has any keys
    const hasKeys = (obj) => obj && Object?.keys(obj)?.length > 0;

    useEffect(() => {
        // Reset the form when the modal is opened and we're in "Add" mode
        if (!hasKeys(props?.updateWalletSettingData)) {
            setTextInput({
                blockchain: "",
                network: "",
                minimumWallets: 0,
                maximumWallets: 1,
                minCapacityThreshold: 0,
                maxCapacityThreshold: 0,
            });
            setError({}); // Reset errors when modal is closed
            dispatch(setErrorMessage(""));
        } else {
            const {
                crypto = {},
                network = "",
                minimumWallets = 0,
                maximumWallets = 1,
                minCapacityThreshold = 0,
                maxCapacityThreshold = 0,
            } = props?.updateWalletSettingData;

            setTextInput({
                blockchain: crypto?.blockchain || "",
                network,
                minimumWallets: Number(minimumWallets),
                maximumWallets: Number(maximumWallets),
                minCapacityThreshold: Number(minCapacityThreshold),
                maxCapacityThreshold: Number(maxCapacityThreshold),
            });
        }
    }, [props?.open, props?.updateWalletSettingData, dispatch]);  // Re-run when modal opens or data changes

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setTextInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setError("");
        dispatch(setErrorMessage(""));
    };

    const handleInputChange = (e, handleChange) => {
        const value = e?.target?.value;
        if (value === "" || Number(value) >= 0) {
            handleChange(e);
        }
    };

    const handleKeyDown = (e) => {
        if (e?.key === "e" || e?.key === "E" || e?.key === "-" || e?.key === "+") {
            e?.preventDefault();
        }
    };

    // Validate the form using Yup schema
    const validateForm = (values) => {
        try {
            const schema = Yup?.object()?.shape({
                minimumWallets: Yup.number()
                    .min(0, "Minimum Wallets cannot be negative")
                    .lessThan(Yup.ref("maximumWallets"), "Min. Wallets should be less than Max. Wallets")
                    .required("Minimum Wallets is required"),
                maximumWallets: Yup.number()
                    .min(1, "Maximum Wallets must be at least 1")
                    .required("Maximum Wallets is required"),
                minCapacityThreshold: CreateOrUpdateWalletSettingsConfig.find(field => field.name === "minCapacityThreshold")?.validation,
                maxCapacityThreshold: CreateOrUpdateWalletSettingsConfig.find(field => field.name === "maxCapacityThreshold")?.validation,
            });
            schema.validateSync(values, { abortEarly: false });
            return {};
        } catch (err) {
            return err?.inner?.reduce(
                (acc, curr) => ({ ...acc, [curr.path]: curr.message }),
                {}
            );
        }
    };

    // Handle the form submit logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const validationError = validateForm(textInput);  // Use the validateForm function here
        if (Object?.keys(validationError)?.length > 0) {
            setError(validationError);
            return;
        }

        const selectedBlockchain = props?.cryptos?.find(
            (crypto) => crypto?.blockchain === textInput?.blockchain
        );
        if (!selectedBlockchain) {
            setError({ blockchain: "No matching blockchain found." });
            return;
        }

        // Prepare request body, ensuring values are numbers where applicable
        const body = {
            id: props?.updateWalletSettingData?._id,
            application: props?.applicationId,
            crypto: selectedBlockchain?._id,
            network: textInput?.network,
            minimumWallets: Number(textInput?.minimumWallets),
            maximumWallets: Number(textInput?.maximumWallets),
            minCapacityThreshold: Number(textInput?.minCapacityThreshold),
            maxCapacityThreshold: Number(textInput?.maxCapacityThreshold),
        };

        // Set endpoint based on the mode (Add or Update)
        const endpoint = hasKeys(props?.updateWalletSettingData)
            ? "/update-wallet-settings"
            : "/save-wallet-settings";

        try {
            // Send API request
            const res = await apiRequest(endpoint, "POST", body);
            if (res?.success) {
                props?.fetchWalletSettingsList("");
                props?.onClose();
            } else {
                // If error comes from the server
                dispatch(setErrorMessage(res?.error || "Something went wrong!"));
            }
        } catch (error) {
            // Handle network or unexpected errors
            dispatch(setErrorMessage("Something went wrong. Please try again."));
        }
    };
    const hanldeModalClose = () => {
        props?.onClose();
        dispatch(setErrorMessage(""));
    }
    return (
        <>
            <Modal open={props?.open} onClose={hanldeModalClose} className="flex justify-center items-center">
                <div className="flex bg-white p-4 px-8 h-max rounded-xl edit-balance">
                    <form onSubmit={handleFormSubmit} className="flex flex-col gap-10">
                        <div className="flex justify-between">
                            <p className="text-2xl text-zinc-900 font-semibold">
                                {hasKeys(props?.updateWalletSettingData) ? "Update Wallet Settings" : "Add Wallet Settings"}
                            </p>
                            <CloseOutlined onClick={hanldeModalClose} className="cursor-pointer" />
                        </div>

                        {/* Display Errors if any */}
                        {error && Object?.keys(error)?.length > 0 && (
                            <div className="text-red-600 text-sm mb-4">
                                {Object?.entries(error)?.map(([key, message]) => (
                                    <p key={key}>{message}</p>
                                ))}
                            </div>
                        )}
                        <div className="flex justify-between">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                                Blockchain<span className="text-red-600">*</span> :
                            </p>
                            <FormControl variant="outlined" size="small" className="text-field">
                                <InputLabel>Blockchain</InputLabel>
                                <Select
                                    name="blockchain"
                                    value={textInput?.blockchain}
                                    onChange={handleChange}
                                    label="Blockchain"
                                    required
                                >
                                    {uniqueBlockchains?.map((blockchain) => (
                                        <MenuItem key={blockchain} value={blockchain}>
                                            {blockchain}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="flex justify-between">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                                Network<span className="text-red-600">*</span> :
                            </p>
                            <FormControl variant="outlined" size="small" className="text-field">
                                <InputLabel>Network</InputLabel>
                                <Select
                                    name="network"
                                    value={textInput.network}
                                    onChange={handleChange}
                                    label="Network"
                                    required
                                >
                                    {props?.networks?.map((network) => (
                                        <MenuItem key={network?.id || network?.network} value={network?.network}>
                                            {network?.network}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        {/* Minimum/Maximum Wallets and Threshold Fields */}
                        {walletSettingsFields?.map((field) => (
                            <div className="flex justify-between" key={field?.name}>
                                <p className="font-normal text-base text-zinc-800 flex items-center">
                                    {field?.label}<span className="text-red-600">*</span> :
                                </p>
                                <TextField
                                    className="text-field"
                                    type="number"
                                    name={field?.name}
                                    value={textInput[field?.name]}
                                    onChange={(e) => handleInputChange(e, handleChange)}
                                    onKeyDown={handleKeyDown}
                                    placeholder={`Enter ${field?.label}`}
                                    variant="outlined"
                                    size="small"
                                    required
                                />
                            </div>
                        ))}

                        <div className="flex justify-end">
                            <CommonButton
                                text={hasKeys(props?.updateWalletSettingData) ? "Update Wallet Settings" : "Add Wallet Settings"}
                                bgColor="black"
                                width="w-48"
                                borderRadius="rounded-3xl"
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
});

export default CreateOrUpdateWalletSettings;
