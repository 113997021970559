//@ts-check
import * as Yup from "yup";

const AddSweepConfig = [
  {
    name: "crypto",
    type: "text",
    value: "",
    validation: Yup.string().required("Value is required"),
  },
  {
    name: "minAmountInWalletToBeSwept",
    type: "number",
    value: 0,
    validation: Yup.number().typeError('Field value must be a number').required("Value is required")
    .min(0,"Value must be greater than or equal to 0")
    .test('is-less-than-max', 'Min value must be less than or equal to Max value', function (value) {
      const { maxAmountInWalletToBeSwept } = this.parent;
      return maxAmountInWalletToBeSwept === '' || value <= maxAmountInWalletToBeSwept;
    }),
  },
  {
    name: "maxAmountInWalletToBeSwept",
    type: "number",
    value: 0,
    validation: Yup.number().typeError('Field value must be a number').required("value is required")
    .min(0,"Value must be greater than or equal to 0")
    .test('is-greater', 'Max value must be greater than or equal to Min value', function (value) {
      const { minAmountInWalletToBeSwept } = this.parent;
      return value >= minAmountInWalletToBeSwept;
    }),
  },
  {
    name: "sweepFlag",
    type: "check",
    value: false,
    validation: Yup.boolean().required("value is required"),
  },
  {
    name: "configFlag",
    type: "check",
    value: false,
    validation: Yup.boolean().required("value is required"),
  },
 
];

export default  AddSweepConfig;
