import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import CustomTabPanel from "../../../../common/CustomTabPanel.js";
import TransactionSummary from "./TransactionSummary.js";
import apiRequest from "../../../../../utils/api.js";
import constant from "../../../../../utils/constant.js";

const TransactionsTab = ({ applicationId }) => {
  const [tabValue, setTabValue] = useState(0);
  const [transactionCount, setTransactionCount] = useState({});

  useEffect(() => {
    const { transactionCategories } = constant;

    const getTransactions = async () => {
      try {
        const res = await apiRequest("/get-transaction-count", "POST", {
          filterType: "Y",
          applicationIds: [applicationId],
        });
        if (res?.success) {
          const data = res?.data;
          const all = {
            Completed: { amount: 0, count: 0 },
            Processing: { amount: 0, count: 0 },
            Rejected: { amount: 0, count: 0 },
            Failed: { amount: 0, count: 0 },
            total: { amount: 0, count: 0 },
          };
          transactionCategories?.forEach((category) => {
            all.Completed.count += data[category]?.Completed?.count;
            all.Processing.count += data[category]?.Processing?.count;
            all.Rejected.count += data[category]?.Rejected?.count;
            all.Failed.count += data[category]?.Failed?.count;
            all.total.count += data[category]?.total?.count;

            all.Completed.amount += data[category]?.Completed?.amount;
            all.Processing.amount += data[category]?.Processing?.amount;
            all.Rejected.amount += data[category]?.Rejected?.amount;
            all.Failed.amount += data[category]?.Failed?.amount;
            all.total.amount += data[category]?.total?.amount;
          });
          setTransactionCount({ data, all });
        }
      } catch (err) {
        console.error(err);
      }
    };
    getTransactions();
  }, [applicationId]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const tabLabels = ["All", "Buy", "Sell", "Receive", "Send", "Exchange"];
  return (
    <Box className="mt-10">
      <Box className="flex justify-between px-6">
        <p className="font-semibold text-xl">Transactions</p>
      </Box>
      <Box className="mt-6 border-[1px] rounded-md mx-6 bg-[#FCFCFC]">
        <Tabs
          className="pt-2 border-b-[2px] pl-4"
          value={tabValue}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
            },
          }}
        >
          {tabLabels.map((label, index) => {
            return (
              <Tab
                key={index}
                label={label}
                sx={{
                  minWidth: "auto",
                  padding: "8px",
                  marginRight: "24px",
                  "&.Mui-selected": {
                    color: "black",
                    fontSize: "16px",
                  },
                  "&.MuiTab-root": {
                    textTransform: "unset !important",
                    fontSize: "16px",
                  },
                }}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
        <CustomTabPanel value={tabValue} index={tabValue}>
          <TransactionSummary
            index={tabValue}
            transactionCount={transactionCount}
          />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default TransactionsTab;
