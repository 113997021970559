import React from "react";
import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material';

const DropDown = (props) => {
  const {
    label,
    value,
    onChange,
    options,
    id,
    disabled = false,
    placeholder = "",
    sx = {},
    fullWidth = false,
    multiple = false,
    defaultValue,
    error = false,
    helperText = "",
  } = props
  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      disabled={disabled}
      sx={sx}
      error={error}
    >
      {label && <InputLabel id={`${id}-label`}>{label}</InputLabel>}
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
        label={label}
        multiple={multiple}
        defaultValue={defaultValue}
        className="w-36 h-8 pr-2 select-class"
      >
        {placeholder && !value && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default DropDown;
