import React from "react";
import { Box } from "@mui/material";
import { getStatusComponent, transactionTypeImage } from "../../../utils/common.js";
import moment from "moment";
import { useSelector } from "react-redux";
import constant from "../../../utils/constant.js";
import ListItem from "./ListItem.js";
import WalletInfo from "./WalletInfo.js";

const TransactionInfoTab = ({ data }) => {
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const junoAdminUiTransactionUrl = useSelector((state) => state.config.junoAdminUiTransactionUrl);

  // Format amount with decimals
  const formatAmount = (amount, decimals) => (amount > 0 ? amount?.toFixed(decimals) : "0.0");

  // Helper function to parse status message (JSON or plain text)
  const parseStatusMessage = (message) => {
    if (!message) return null;
    try {
      return JSON.parse(message);
    } catch (error) {
      return message;
    }
  };
  const parsedStatusMessage = parseStatusMessage(data?.status?.message);
  const hasValidLink = data?.applicationRefId && junoAdminUiTransactionUrl;
  const linkUrl = hasValidLink ? `${junoAdminUiTransactionUrl}/${data?.applicationRefId}` : "";
  const itemValue = data?.applicationRefId || 'N/A';
  return (
    <Box className="border-[1px] rounded-lg m-6 p-6 text-sm flex flex-col gap-2">
      <ListItem label="Application Name" value={data?.application?.name} copyText />
      <ListItem label="Account ID" value={data?.application?.applicationId} copyText />
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction Type:</p>
        <Box className="flex items-center">
          {transactionTypeImage(data?.type, imageBaseURL)}
          <span className="p-2">{data?.type || "N/A"}</span>
        </Box>
      </Box>
      <hr />
      <ListItem label="Transaction Initiated" value={moment(data?.createdDate).format("DD/MM/YYYY - HH:mm:ss")} />
      <hr />
      <ListItem label="Last Updated" value={moment(data?.updatedDate).format("DD/MM/YYYY - HH:mm:ss")} />
      <hr />
      <ListItem label="Transaction Status" value={data?.status?.code} />
      <hr />
      <ListItem label="Status" value={getStatusComponent(data?.status?.code)} />
      <hr />
      <div className="flex items-center justify-between">
        <h4 className="text-gray-600 w-32">Status Message:</h4>
        <Box className="overflow-y-auto max-h-48 w-full ml-2 p-2 flex justify-end">
          {parsedStatusMessage || data?.status?.message ? (
            <pre className="text-sm whitespace-pre-wrap break-words text-right">
              {JSON.stringify(parsedStatusMessage || data?.status?.message, null, 2)}
            </pre>
          ) : (
            <p>N/A</p>
          )}
        </Box>
      </div>
      <hr />
      <ListItem label="Transaction ID" value={data?.transactionId} copyText />
      <ListItem
        label="Juno Transaction ID"
        value={itemValue}
        link={linkUrl}
        copyText
      />
      <ListItem label="Blockchain" value={data?.blockchain} copyText />
      <ListItem label="Network" value={data?.network} copyText />
      {data?.type !== constant.transactionType?.exchange && (
        <ListItem label="Crypto Currency" value={data?.cryptoSymbol} copyText />
      )}
      <hr />
      {data?.fromWallet && (
        <WalletInfo
          label="From Wallet"
          walletData={data?.fromWallet}
          blockchain={data?.blockchain}
          network={data?.network}
        />
      )}
      {data?.toWallet && (
        <WalletInfo
          label="To Wallet"
          walletData={data?.toWallet}
          blockchain={data?.blockchain}
          network={data?.network}
        />
      )}
      {/* Rendered only for exchange transactions */}
      {data?.type === constant.transactionType?.exchange && (
        <>
          <ListItem label="From Crypto Currency" value={data?.fromCrypto?.cryptoSymbol} copyText />
          <ListItem label="From Crypto Amount" value={data?.fromCrypto?.amount} copyText />
          <ListItem label="To Crypto Currency" value={data?.toCrypto?.cryptoSymbol} copyText />
          <ListItem label="To Crypto Amount" value={data?.toCrypto?.amount} copyText />
        </>
      )}
      {/* Rendered for all other transactions */}
      {data?.type !== constant.transactionType?.exchange && (
        <>
          <ListItem label="Crypto Amount" value={formatAmount(data?.cryptoAmount, 6)} copyText />
          <ListItem label="Fiat Amount" value={formatAmount(data?.fiatAmount, 2)} copyText />
        </>
      )}
      {data?.autoTrade && (
        <>
          <ListItem label="Trade ID" value={data?.autoTrade?.tradeOrderId} copyText />
          <ListItem label="Currency" value={data?.autoTrade?.sendOrReceiveCurrency} copyText />
          <ListItem label="Quantity" value={data?.autoTrade?.sendOrReceiveQuantity} copyText />
        </>
      )}
    </Box>
  );
};

export default TransactionInfoTab;
