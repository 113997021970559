import RoutesList from "./routes/Routes.js";
import CryptoLoader from "./components/common/CryptoLoader.js";
import { useSelector } from "react-redux";
import AxiosInterceptor from "./components/common/AxiosInterceptor.js";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import { useState, useEffect } from "react";
import NetworkError from "./utils/NetworkError.js";
import CommonSnackbar from "./components/common/CommonSnackbar.js";

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
});

function App() {
  const loading = useSelector((state) => state.common.loading?.isLoading);
  const errorMessage = useSelector((state) => state.common.errorMessage);
  const [networkError, setNetworkError] = useState(false); // State to track network errors
  const [disableReload, setDisableReload] = useState(false); // State to disable reload button

  const role = localStorage.getItem("role");
  const userApplicationId = localStorage.getItem("applicationId");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if (!role || !userApplicationId) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }

    // Handle the online event to remove network error when network is back
    const handleOnline = () => {
      setNetworkError(false);
    };

    // Handle the offline event to show network error
    const handleOffline = () => {
      setNetworkError(true);
    };

    // Attach event listeners for network status changes
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check initial network status when the component is mounted
    if (!navigator?.onLine) {
      setNetworkError(true);
    }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [role, userApplicationId]);

  const handleReload = () => {
    setDisableReload(true); // Disable the reload button after clicking it
    window.location.reload();
  };

  // Function to handle network errors (called by Axios interceptor)
  const handleNetworkError = () => {
    setNetworkError(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {loading && <CryptoLoader />}
        
        {/* Pass handleNetworkError function to AxiosInterceptor */}
        <AxiosInterceptor onNetworkError={handleNetworkError} />

        {networkError && (
          <NetworkError
            onClick={handleReload}
            disableReload={disableReload}
            showReload={true}
          />
        )}

        <RoutesList />
         {/* Global Snackbar */}
         {errorMessage && <CommonSnackbar />}
      </div>
    </ThemeProvider>
  );
}

export default App;
