import React, { useState, useEffect } from "react";
import { Box, MenuItem } from "@mui/material";
import CommonDatePicker from "../common/CommonDatePicker.js";
import apiRequest from "../../utils/api.js";
import FilterDropdown from "../common/FilterDropdown.js";
import { useSelector } from "react-redux";
import CommonButton from "../common/CommonButton.js";

const TransactionListFilters = (props) => {
  const [inputFields, setInputFields] = useState({
    type: [],
    applicationId: "",
    status: [],
    cryptoCurrency: [],
    fromDate: "",
    toDate: "",
  });
  const [allApplications, setAllApplications] = useState([]);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  useEffect(() => {
    const getApplications = async () => {
      const res = await apiRequest("/get-all-application", "POST");
      if (res?.success) {
        setAllApplications(res.data);
      }
    };
    getApplications();
  }, []);

  const { setFilters, setPage, handleDrawer } = props;

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (name === "type" || name === "status" || name === "cryptoCurrency") {
      if (value.length && value[value.length - 1] === 'All') {
        value = ['All']
      } else if (value.includes('All')) {
        value = value.filter(item => item !== 'All')
      }
    }
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSubmit = () => {
    setPage(1);
    let inputFieldsType = inputFields.type, inputFieldsStatus = inputFields.status, inputFieldsCryptoCurrency = inputFields.cryptoCurrency
    if (inputFields.type.includes('All')) {
      inputFieldsType = transactionType.filter((item) => item.key !== 'All').map((item) => item.key);
    }
    if (inputFields.status.includes('All')) {
      inputFieldsStatus = transactionStatus.filter((item) => item.key !== 'All').map((item) => item.key);
    }
    if (inputFields.cryptoCurrency.includes('All')) {
      inputFieldsCryptoCurrency = transactionCrypto.filter((item) => item.key !== 'All').map((item) => item.key);
    }
    const filters = {
      ...inputFields,
      type: inputFieldsType,
      status: inputFieldsStatus,
      cryptoCurrency: inputFieldsCryptoCurrency,

    }
    setFilters(filters);
    handleDrawer(false);
  };

  const reset = () => {
    setPage(1);
    setFilters({});
    setInputFields({
      type: [],
      applicationId: "",
      status: [],
      cryptoCurrency: [],
      fromDate: null,
      toDate: null,
    });
  };

  const transactionCrypto = [
    { cryptoCurrency: "All", key: "All" },
    { cryptoCurrency: "ETH", key: "ETH" },
    { cryptoCurrency: "LTC", key: "LTC" },
    { cryptoCurrency: "XRP", key: "XRP" },
    { cryptoCurrency: "TRX", key: "TRX" },
    { cryptoCurrency: "DAI", key: "DAI" },
    { cryptoCurrency: "USDT", key: "USDT" },
    { cryptoCurrency: "USDC", key: "USDC" },
    { cryptoCurrency: "BTC", key: "BTC" },
  ]

  const transactionType = [
    { type: "All", key: "All" },
    { type: "Buy", key: "buy" },
    { type: "Sell", key: "sell" },
    { type: "Receive", key: "receive" },
    { type: "Send", key: "send" },
    { type: "Exchange", key: "exchange" },
    { type: "Sweep Transfer", key: "SweepTransfer" },
    { type: "AccountWalletUpdate", key: "AccountWalletUpdate" },
  ];
  const transactionStatus = [
    { status: "All", key: "All" },
    { status: "Completed", key: "completed" },
    { status: "Processing", key: "Processing" },
    { status: "Rejected", key: "rejected" },
    { status: "Failed", key: "failed" },
  ];

  const setDateRange = (startDate, endDate) => {
    setInputFields((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  };

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex flex-wrap gap-4">
        <FilterDropdown
          label="Transaction Type"
          value={inputFields.type}
          name="type"
          data={transactionType}
          handleInputChange={handleInputChange}
          isMultiple={true}
          placeholder="Select an option"
          className="transaction-filter"
          placeholderClass="custom-placeholder"
          menuClass="custom-menu"
          useDisplayValue={true}
        >
          {transactionType?.length > 0 &&
            transactionType.map((item, index) => {
              return (
                <MenuItem
                  className={`${inputFields.type.includes("All") ? "Mui-selected" : ""} 
                ${item.disableHover ? "no-hover" : ""} 
                ${item?.type === "All" ? "all-option-selected" : ""}`}
                  key={`${item?.type}${index}`} value={item?.key}>
                  {item?.type}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Application Name"
          value={inputFields?.applicationId}
          name="applicationId"
          data={allApplications}
          handleInputChange={handleInputChange}
          placeholder="Select an option"
          className="transaction-filter"
          placeholderClass="custom-placeholder"
          menuClass="custom-menu"
          useDisplayValue={true}
          allApplications={allApplications}
        >
          {allApplications?.length > 0 &&
            allApplications.map((item, index) => {
              return (
                <MenuItem
                  key={`${item?._id}${index}`}
                  value={item?._id}
                >
                  {item?.name}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Status"
          value={inputFields.status}
          name="status"
          data={transactionStatus}
          isMultiple={true}
          handleInputChange={handleInputChange}
          placeholder="Select an option"
          className="transaction-filter"
          placeholderClass="custom-placeholder"
          menuClass="custom-menu-filter"
          useDisplayValue={true}
        >
          {transactionStatus?.length > 0 &&
            transactionStatus.map((item, index) => {
              return (
                <MenuItem
                  className={`${inputFields.status.includes("All") ? "Mui-selected" : ""}
                ${item.disableHover ? "no-hover" : ""}
                ${item?.status === "All" ? "all-option-selected" : ""}`}
                  key={`${item?.status}${index}`} value={item?.key}>
                  {item?.status}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <FilterDropdown
          label="Crypto Currency"
          value={inputFields?.cryptoCurrency || "Select an option"}
          name="cryptoCurrency"
          data={transactionCrypto}
          isMultiple={true}
          handleInputChange={handleInputChange}
          placeholder="Select an option"
          className="transaction-filter"
          placeholderClass="custom-placeholder"
          menuClass="custom-menu"
          useDisplayValue={true}
        >
          {transactionCrypto?.length > 0 &&
            transactionCrypto.map((item, index) => {
              return (
                <MenuItem
                  className={`${inputFields.cryptoCurrency.includes("All") ? "Mui-selected" : ""} 
                ${item.disableHover ? "no-hover" : ""}
                ${item?.cryptoCurrency === "All" ? "all-option-selected" : ""}`}
                  key={`${item?.cryptoCurrency}${index}`} value={item?.key}>
                  {item?.cryptoCurrency}
                </MenuItem>
              );
            })}
        </FilterDropdown>
        <Box>
          <p className="text-sm mb-2 font-normal text-[#51525C]">Date Range</p>
          <CommonDatePicker setDateRange={setDateRange} startDate={inputFields?.fromDate} endDate={inputFields?.toDate} />
        </Box>
      </Box>
      <Box className="flex self-end gap-2 mb-10  md:mr-30">
        <CommonButton size="large" variant="contained" text="Reset" onClick={reset} bgColor="white"
          StartIcon={<img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="reload-icon"
            className="w-6 h-6 mr-2"
          />}>
        </CommonButton>
        <CommonButton size="large" variant="contained" text="Search" onClick={onSubmit} bgColor="black"
          StartIcon={<img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="search-icon"
            className="w-6 h-6 mr-2"
          />}>
        </CommonButton>
      </Box>
    </Box>
  );
};

export default TransactionListFilters;
