import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer.js";
import constant from "../utils/constant.js";
const configureAppStore = () => {
  const BLOCKCHAIN = constant?.BLOCKCHAIN
  const NETWORK = constant?.NETWORK
  const initialState =
    window && process.env.NODE_ENV !== "development"
      ? {
        config: {
          apiUrl: window.INITIAL_STATE?.config?.RESTAPI_URL,
          imageBaseUrl: window.INITIAL_STATE?.config?.IMAGE_BASE_URL,
          junoAdminUiTransactionUrl: window.INITIAL_STATE?.config?.JUNO_ADMIN_UI_TRANSACTION_URL,
          blockchainExplorer: {
            [BLOCKCHAIN.BTC]: {
              [NETWORK.MAINNET]: window.INITIAL_STATE?.config?.BTC_MAINNET_URL, 
              [NETWORK.TESTNET]: window.INITIAL_STATE?.config?.BTC_TESTNET_URL,  
            },
            [BLOCKCHAIN.ETH]: {
              [NETWORK.MAINNET]: window.INITIAL_STATE?.config?.ETH_MAINNET_URL,  
              [NETWORK.TESTNET]: window.INITIAL_STATE?.config?.ETH_TESTNET_URL,  
            },
            [BLOCKCHAIN.LTC]: {
              [NETWORK.MAINNET]: window.INITIAL_STATE?.config?.LTC_MAINNET_URL,  
              [NETWORK.TESTNET]: window.INITIAL_STATE?.config?.LTC_TESTNET_URL,  
            },
            [BLOCKCHAIN.TRON]: {
              [NETWORK.MAINNET]: window.INITIAL_STATE?.config?.TRON_MAINNET_URL,  
              [NETWORK.TESTNET]: window.INITIAL_STATE?.config?.TRON_TESTNET_URL,  
            },
            [BLOCKCHAIN.XRP]: {
              [NETWORK.MAINNET]: window.INITIAL_STATE?.config?.XRP_MAINNET_URL,  
            },
          },
          adminLogin2FA: window.INITIAL_STATE?.config?.ADMIN_LOGIN_2FA,
        },
      }
      : {
        config: {
          apiUrl: "http://localhost:8080", // For local development
          imageBaseUrl: process.env.REACT_APP_IMAGE_URL || "http://localhost:3000/images", // Use dev URL or fallback to localhost
          junoAdminUiTransactionUrl: process.env.JUNO_ADMIN_UI_TRANSACTION_URL || "https://dev1-admin.junomoney.org/transactions", // Use dev URL
          blockchainExplorer: {
            [BLOCKCHAIN.BTC]: {
              [NETWORK.MAINNET]: process.env.BTC_MAINNET_URL || "https://www.blockchain.com/btc/tx",  
              [NETWORK.TESTNET]: process.env.BTC_TESTNET_URL || "https://www.blockchain.com/btc-testnet/tx", 
            },
            [BLOCKCHAIN.ETH]: {
              [NETWORK.MAINNET]: process.env.ETH_MAINNET_URL || "https://etherscan.io/tx", 
              [NETWORK.TESTNET]: process.env.ETH_TESTNET_URL || "https://sepolia.etherscan.io/tx", 
            },
            [BLOCKCHAIN.LTC]: {
              [NETWORK.MAINNET]: process.env.LTC_MAINNET_URL || "https://blockchair.com/litecoin/transaction", 
              [NETWORK.TESTNET]: process.env.LTC_TESTNET_URL || "https://blockexplorer.one/litecoin/testnet/tx", 
            },
            [BLOCKCHAIN.TRON]: {
              [NETWORK.MAINNET]: process.env.TRON_MAINNET_URL || "https://tronscan.org/#/transaction", 
              [NETWORK.TESTNET]: process.env.TRON_TESTNET_URL || "https://shasta.tronscan.org/#/transaction", 
            },
            [BLOCKCHAIN.XRP]: {
              [NETWORK.MAINNET]: process.env.XRP_MAINNET_URL || "https://blockchair.com/ripple/account", 
            },
          },
          adminLogin2FA: process.env.REACT_APP_ADMIN_LOGIN_2FA || "false", // Use env var for 2FA or fallback to "false"
        },
      };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
};

export default configureAppStore;
