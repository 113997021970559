import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import FilterTextField from "../../../../common/FilterTextField.js";
import { useSelector } from "react-redux";

/**
 * Component for applying filters to the Wallet Settings list, including options for crypto, network, and date range.
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - Function to set filters in the parent component.
 * @param {Function} props.closeDrawer - Function to close the filter drawer.
 * @param {Function} props.setPage - Function to set the page number.
 * @returns {JSX.Element} WalletSettingsFilters component
 */
const WalletSettingsFilters = React.memo(function WalletSettingsFilters(props) {
  const { setFilters, closeDrawer, setPage } = props;  // Destructuring within the function body

  const [inputFields, setInputFields] = useState({
    blockchain: "",
    network: "",
  });

  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

  /**
   * Handles changes in filter input fields and updates the state.
   * @param {Object} e - The change event object.
   */
  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * Submits the current filters and closes the drawer.
   */
  const onSubmit = () => {
    setPage(1);
    setFilters(inputFields);
    closeDrawer();
  };

  /**
   * Resets all filter fields to their initial state and clears the filters.
   */
  const reset = () => {
    setPage(1);
    setFilters({});
    setInputFields({
      blockchain: "",
      network: "",
    });
  };

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterTextField
          label="Blockchain"
          name="blockchain"
          placeholder="Search by blockchain"
          handleInputChange={handleInputChange}
          value={inputFields?.blockchain}
        />
        <FilterTextField
          label="Network"
          name="network"
          placeholder="Search by network"
          handleInputChange={handleInputChange}
          value={inputFields?.network}
        />
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="Reload Icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="Search Icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
});

export default WalletSettingsFilters;
