
import React from "react";
import constant from "../../../utils/constant.js";
import ListItem from "./ListItem.js";
import { generateBlockchainExplorerUrl } from "../../../utils/common.js";
import { useSelector } from "react-redux";

const WalletInfo = (props) => {
    const { label, walletData, blockchain, network } = props;
    const blockchainExplorer = useSelector((state) => state.config.blockchainExplorer);
  
    const link = walletData?.type === constant?.WalletType?.USER
      ? `/wallets/${walletData?.address}`
      : walletData?.type === constant?.WalletType?.CENTRALIZED
        ? `/centralised-wallets/${walletData?.address}`
        : null;
  
    const arrowLink = walletData?.address
      ? generateBlockchainExplorerUrl(walletData?.address, blockchain, network, blockchainExplorer, constant?.WALLET)
      : null;
  
    return walletData?.address ? (
      <ListItem
        label={label}
        value={walletData?.address}
        copyText
        link={link}
        arrowLink={arrowLink}
      />
    ) : null;
  };
  
  export default WalletInfo;
  