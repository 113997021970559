import React, { memo } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CustomTabPanel from "../../../common/CustomTabPanel.js";
import TransactionsTab from "./Transactions/TransactionsTab.js";
import GlobalCryptoTab from "./Crypto/GlobalCryptoTab.js";
import IpWhiteListing from "./IpWhiteListing/IpWhiteListing.js";
import Webhooks from "./Webhooks/Webhooks.js";
import APIKey from "./APIKey/APIKey.js";
import "../../css/tabs.css";
import DeleteApplication from "./DeleteApplication/DeleteApplication.js";
import AuditTrail from "./AuditTrail/AuditTrail.js"
import WalletSettings from "./WalletSettings/WalletSettingsList.js";
import { useSelector } from "react-redux";

const ApplicationTabs = ({ tabValue, handleChange, applicationId }) => {
  const userRole = localStorage.getItem("role");
  const applicationData = useSelector(
    (state) => state.globalSettings.application?.data || {}
  );
  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });
  const tabLabels = ["Crypto", "Transactions", "Webhooks", "IP Whitelisting", "API Key", "Audit Trail", "Wallet Settings"];
  if (userRole === "SuperAdmin") {
    tabLabels.push("Delete Application");
  }
  return (
    <Box className="w-full overflow-y-scroll">
      <Tabs
        className="border-b-[2px] mt-4 pl-6 customTab"
        id="tabs"
        value={tabValue}
        onChange={handleChange}
      >
        {tabLabels.map((label, index) => {
          return (
            <Tab
              key={`ApplicationTab${index}`}
              label={label}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <GlobalCryptoTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <TransactionsTab applicationId={applicationData?._id} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <Webhooks />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <IpWhiteListing />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <APIKey />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={7}>
        <DeleteApplication title={applicationData?.name} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={5}>
        <AuditTrail applicationId={applicationData?._id} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={6}>
        <WalletSettings applicationId={applicationData?._id} />
      </CustomTabPanel>
    </Box>
  );
};

export default memo(ApplicationTabs);
