import React, { memo, useState } from "react";
import { Divider, Modal, TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material/index.js";
import CommonButton from "../common/CommonButton.js";
import Checkbox from "../common/CheckBox.js";
import { useSelector } from "react-redux";
import CommonDropDown from "../common/CommonDropDown.js";
import apiRequest from "../../utils/api.js";
import * as Yup from "yup";
import AddSweepConfig from "../../formSchema/AddSweepConfig.js";
import { Form, Field } from "react-final-form";

const validateForm = (values) => {
  try {
    const schema = Yup.object().shape(
      AddSweepConfig.reduce(
        (acc, curr) => ({ ...acc, [curr.name]: curr.validation }),
        {}
      )
    );
    schema.validateSync(values, { abortEarly: false });
    return {};
  } catch (err) {
    return err.inner.reduce(
      (acc, curr) => ({ ...acc, [curr.path]: curr.message }),
      {}
    );
  }
};

const AddConfiguration = (props) => {
  const {
    open,
    close,
    cryptoCurrencyList,
    setReload,
  } = props;
  const [errorMessage, setErrorMessage] = useState('')
  const onSubmit = async (values, { reset }) => {
    try {
      const body = {
        crypto: values?.crypto,
        minAmountUsd: values?.minAmountInWalletToBeSwept,
        maxAmountUsd: values?.maxAmountInWalletToBeSwept,
        sweepFlag: Boolean(values?.sweepFlag),
        configFlag: Boolean(values?.configFlag),
      };
      const response = await apiRequest("/create-sweep-config", "POST", {
        ...body,
      });
      if(!response?.success) throw new Error(response?.error);
      reset();
      setReload((prev) => !prev);
      close();
    } catch (error) {
      console.error("Error in sweepConfig create", error?.message);
      setErrorMessage(error?.message);
    }
  };
const fieldOnChange = (form,{ name, value})=>{
  setErrorMessage('');
  form.change(
    name,
    value
  );
}
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  return (
    <Modal
      open={open}
      onClose={close}
      className="flex justify-center items-center"
    >
      <>
      <Form
        onSubmit={onSubmit}
        initialValues={AddSweepConfig.reduce(
          (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
          {}
        )}
        validate={validateForm}
        render={({
          handleSubmit,
          form,
          submitting,
          invalid,
          pristine,
          values,
          errors,
          touched,
        }) => {
          return (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col bg-white h-max rounded-xl add-configuration"
            >
              <div className="p-4 px-8">
                <div className="flex flex-col gap-10">
                  <div className="flex justify-between">
                    <p className="text-2xl text-zinc-900 font-semibold">
                      Add Configuration
                    </p>
                    <CloseOutlined onClick={close} className="cursor-pointer" />
                  </div>
                  <div className="flex flex-col gap-8 overflow-y-auto max-h-96 scroll-hidden">
                    <Field name="crypto">
                      {({ input, meta }) => {
                        return (
                          <div className="flex justify-between items-center">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                              Crypto :
                            </p>
                            <CommonDropDown
                              required={true}
                              options={cryptoCurrencyList?.map((item) => ({
                                value: item?._id,
                                label: item?.symbol,
                              }))}
                              name="crypto"
                              placeHolder="Select Crypto"
                              value={input?.value}
                              onChange={(event)=>{
                                fieldOnChange(form, event?.target);
                              }}
                              onBlur={input?.onBlur}
                              touched={meta?.touched}
                              error={meta?.error}
                              image={
                                <img
                                  src={`${imageBaseURL}/images/caas-admin-ui/icons/usdt.png`}
                                  alt="add-icon"
                                  className="w-5 h-5"
                                />
                              }
                            />
                          </div>
                        );
                      }}
                    </Field>

                    {/* <Field name="remainingFullWalletsAfterSweep">
                      {({ input, meta }) => {
                        return (
                          <div className="flex justify-between items-center">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                              Remaining full
                              <br /> wallets after <br />
                              sweep :
                            </p>
                            <TextField
                              className="text-field"
                              type="number"
                              name="remainingFullWalletsAfterSweep"
                              variant="outlined"
                              size="small"
                              value={input?.value}
                              onChange={(event)=>{
                                fieldOnChange(form, event?.target);
                              }}
                              onBlur={input?.onBlur}
                              touched={meta?.touched?'1': undefined}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && meta.error ? meta.error : ""
                              }
                            />
                          </div>
                        );
                      }}
                    </Field> */}

                    <Field name="minAmountInWalletToBeSwept">
                      {({ input, meta }) => {
                        return (
                          <div className="flex justify-between items-center">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                              Min. amount in
                              <br /> wallet to be <br />
                              swept :
                            </p>
                            <TextField
                              className="text-field"
                              type="number"
                              name="minAmountInWalletToBeSwept"
                              variant="outlined"
                              size="small"
                              value={input?.value}
                              onChange={(event)=>{
                                fieldOnChange(form, event?.target);
                              }}
                              onBlur={input?.onBlur}
                              touched={meta?.touched?'1': undefined}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && meta.error ? meta.error : ""
                              }
                            />
                          </div>
                        );
                      }}
                    </Field>
                    <Field name="maxAmountInWalletToBeSwept">
                      {({ input, meta }) => {
                        return (
                          <div className="flex justify-between items-center">
                            <p className="font-normal text-base text-zinc-800 flex items-center">
                              Max. amount in
                              <br /> wallet to be
                              <br /> swept :
                            </p>
                            <TextField
                              className="text-field"
                              type="number"
                              name="maxAmountInWalletToBeSwept"
                              variant="outlined"
                              size="small"
                              value={input?.value}
                              onChange={(event)=>{
                                fieldOnChange(form, event?.target);
                              }}
                              onBlur={input?.onBlur}
                              touched={meta?.touched?'1': undefined}
                              error={meta.touched && !!meta.error}
                              helperText={
                                meta.touched && meta.error ? meta.error : ""
                              }
                            />
                          </div>
                        );
                      }}
                    </Field>
                    <div>
                      <Field name="sweepFlag">
                        {({ input, meta }) => {
                          return (
                            <div className="flex gap-4 items-center">
                              <Checkbox
                                name="sweepFlag"
                                checked={input?.value}
                                handleChange={(e) => {
                                  fieldOnChange(form, {
                                  name: e?.target?.name,
                                  value: e?.target?.checked
                                });
                                }}
                              />
                              <p>Perform Actual Sweep Transaction</p>
                            </div>
                          );
                        }}
                      </Field>

                      <Field name="configFlag">
                        {({ input, meta }) => {
                          return (
                            <div className="flex gap-4 items-center">
                              <Checkbox
                                name="configFlag"
                                checked={input?.value}
                                handleChange={(e) => {
                                  fieldOnChange(form, {
                                  name: e?.target?.name,
                                  value: e?.target?.checked
                                });
                                }}
                              />
                              <p>Config Enabled/Disable</p>
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <Divider />
                {errorMessage && <p className="text-red-600 text-center">{errorMessage}</p>}
                <div className="flex justify-end px-8 pb-4">
                  <CommonButton
                    disabled={submitting || invalid}
                    text="Add Config"
                    bgColor="black"
                    width="w-36"
                    borderRadius="rounded-3xl"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          );
        }}
      ></Form>
      </>
    </Modal>
  );
};

export default memo(AddConfiguration);
