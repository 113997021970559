import React, { useEffect } from "react";
import { Snackbar } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../redux/actions/commonAction.js";

const CommonSnackbar = () => {
  const dispatch = useDispatch();
  const { errorMessage } = useSelector((state) => state.common);
  const snackbarOpen = Boolean(errorMessage);  // Snackbar opens when there's an error message

  const handleSnackbarClose = () => {
    dispatch(setErrorMessage(""));  // Clear error message when Snackbar is closed
  };

  // Automatically close the Snackbar after a set time (optional)
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        dispatch(setErrorMessage());  // Clear error after 6 seconds
      }, 6000);

      return () => clearTimeout(timer);  // Cleanup the timer if component unmounts
    }
  }, [errorMessage, dispatch]);

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={errorMessage}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={
        <CloseOutlined onClick={handleSnackbarClose} className="cursor-pointer" />
      }
    />
  );
};

export default CommonSnackbar;
