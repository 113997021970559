import React from "react";
import { Box } from "@mui/material";
import CopyButton from "../../common/CopyButton.js";
import { Launch } from '@mui/icons-material';

const ListItem = (props) => {
  const { label, value, copyText, link, arrowLink } = props;

  return (
    <Box className="flex items-center justify-between">
      <p className="text-gray-600">{label}:</p>
      <div className={`${!copyText ? "p-2" : ''} text-end flex items-center`}>
        {value ? (
          link ? (
            <a href={link} target="_blank" rel="noopener noreferrer" className="text-blue-500 no-underline">
              {value}
            </a>
          ) : (
            value
          )
        ) : "N/A"}
        {copyText && <CopyButton textToCopy={value} />}
        {/* Render the arrow icon for blockchain explorer link */}
        {arrowLink && (
          <a href={arrowLink} target="_blank" rel="noopener noreferrer">
            <Launch className="ml-2 mb-1 text-gray-600" />
          </a>
        )}
      </div>
    </Box>
  );
};

export default ListItem;
