import { SET_LOADING, SET_ALL_APPLICATIONS, SET_TRANSACTION_NOTES, SET_LIST_CRYPTO, SET_TRANSFER_WALLET_ADDRESS_lIST, SET_ERROR_MESSAGE } from "./actionTypes.js";

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: { isLoading },
});

export const setAllApplications = (allApplications) => ({
  type: SET_ALL_APPLICATIONS,
  payload: { allApplications },
});

export const setTransactionNotes = (notes) => ({
  type: SET_TRANSACTION_NOTES,
  payload: { notes },
});

export const setListCrypto = (listCrypto) => ({
  type: SET_LIST_CRYPTO,
  payload: { listCrypto },
})

export const setTransferWalletAddressList = (walletAddress) => ({
  type: SET_TRANSFER_WALLET_ADDRESS_lIST,
  payload: { walletAddress },
})

export const setErrorMessage = (errorMessage) => ({
  type: SET_ERROR_MESSAGE,
  payload: { errorMessage },
})