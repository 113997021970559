const transactionCategories = ["Buy", "Sell", "Receive", "Send", "Exchange"];
const transactionType = {
  buy: "Buy",
  sell: "Sell",
  receive: "Receive",
  send: "Send",
  exchange: "Exchange",
  sweepTransfer: "Sweep Transfer",
}

const walletBlockchain = [
  { blockchain: "All", key: "All" },
  { blockchain: "Bitcoin", key: "Bitcoin" },
  { blockchain: "Ethereum", key: "Ethereum" },
  { blockchain: "Litecoin", key: "Litecoin" },
  { blockchain: "Ripple", key: "Ripple" },
  { blockchain: "Tron", key: "Tron" },
];

const walletCrypto = [
  { crypto: "All", key: "All" },
  { crypto: "ETH", key: "ETH" },
  { crypto: "LTC", key: "LTC" },
  { crypto: "XRP", key: "XRP" },
  { crypto: "TRX", key: "TRX" },
  { crypto: "DAI", key: "DAI" },
  { crypto: "USDT", key: "USDT" },
  { crypto: "USDC", key: "USDC" },
  { crypto: "BTC", key: "BTC" },
];

const excludedCrypto = ["TRX", "TRC20-USDT"];
const BLOCKCHAIN = {
  ETH: 'Ethereum',
  BTC: 'Bitcoin',
  LTC: 'Litecoin',
  TRON: 'Tron'
};
const NETWORK = {
  MAINNET: 'mainnet',
  TESTNET: 'testnet'
};
const walletStatus = ["Available", "In-Use", "Locked", "Full", "ARCHIVED"]
const WALLET = "wallet";
const WalletType = {
  NONE: 0,
  USER: 1,
  CENTRALIZED: 2,
};
const constant = { 
  transactionCategories, 
  walletBlockchain, 
  walletCrypto, 
  transactionType, 
  excludedCrypto, 
  walletStatus,
  BLOCKCHAIN,
  NETWORK,
  WALLET,
  WalletType
};
export default constant;
