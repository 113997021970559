export const SET_APPLICATION = "SET_APPLICATION";
export const SET_LOADING = "SET_LOADING";
export const SET_APPLICATION_IDS = "SET_APPLICATION_IDS";
export const SET_ALL_APPLICATIONS = "SET_ALL_APPLICATIONS";
export const SET_TRANSACTION_NOTES = "SET_TRANSACTION_NOTES";
export const SET_LIST_CRYPTO = "SET_LIST_CRYPTO";
export const SET_TRANSFER_WALLET_ADDRESS_lIST = "SET_TRANSFER_WALLET_ADDRESS_lIST"
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";


