import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Breadcrumbs,
    Link,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell/index.js";
import { useParams, Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import CommonPagination from "../../common/CommonPagination.js";
import CopyButton from "../../common/CopyButton.js";
import DropDown from "../../common/DropDown.js";
import { getCryptoImage, getCryptoLink, onDownloadClick } from "../../../utils/common.js";
import CommonButton from "../../common/CommonButton.js";
import CommonTable from "../../common/CommonTable.js";
import apiRequest from "../../../utils/api.js";
import EditBalance from "../../common/CommonEditBalance.js";
import useDebounce from "../../../customHook/useDebounce.js";
import { checkViewPermission } from "../../../utils/common.js";
import constant from "../../../utils/constant.js";
import { getShortenedTransactionId } from "../../../utils/common.js";

const FeeWalletDetails = () => {
    const isViewPermissionValid = checkViewPermission()
    const [openEditBalance, setOpenEditBalance] = useState(false);
    const [sortConfig, setSortConfig] = useState({});
    const [balanceData, setBalanceData] = useState({});
    const [feeWalletData, setFeeWalletData] = useState({
        balance: [],
        balanceHistory: [],
        balanceAmountUSDApprox: [],
        feeWalletDetailObj: {},
        status: ""
    })
    const [paginationConfig, setPaginationConfig] = useState({
        page: 1,
        totalPages: 1,
    });
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);
    const params = useParams();
    const debouncedSearchHandler = useDebounce((value) => {
        setDebouncedSearch(value);
    }, 1000);

    const handleCloseToast = (event, reason) => {
        if (reason === "clickaway") return;
        setToastMessage("");
    };

    const openEditBalanceModal = (balanceData) => {
        setBalanceData(balanceData);
        setOpenEditBalance(!openEditBalance);
    };

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="inherit"
            component={RouterLink}
            to="/"
        >
            <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_dashboard.png`}
                alt="dashboardIcon"
                className="w-6 h-6"
            />
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            component={RouterLink}
            to="/fee-wallets"
            className="flex gap-2"
        >
            <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_wallet.png`}
                alt="dashboardIcon"
                className="w-6 h-6"
            />
            <span className="mr-4">Fee Wallets</span>
        </Link>,
        <Typography key="3" color="text.primary">
            <span className="ml-3 text-[#18181B]">
                Wallet Address: {params?.addressKey}
                <CopyButton textToCopy={params?.addressKey} />
            </span>
        </Typography>,
    ];

    const handleChangeSearchText = (e) => {
        debouncedSearchHandler(e.target.value);
    };

    const fetchFeeWalletDetailsData = useCallback(
        async () => {
            if (debouncedSearch.length >= 3 || debouncedSearch.length === 0) {
                const res = await apiRequest("/get-fee-wallet-details", "POST", {
                    addressKey: params?.addressKey,
                    page: paginationConfig?.page,
                    searchText: debouncedSearch,
                    orderBy: sortConfig?.orderBy,
                    sortBy: sortConfig?.sortBy,
                });
                if (res?.success) {
                    setFeeWalletData({
                        balance: res?.data?.feeWallet?.balance,
                        balanceHistory: res?.data?.feeWallet?.balanceHistory,
                        balanceAmountUSDApprox: res?.data?.feeWallet?.balanceAmountUSDApprox,
                        feeWalletDetailObj: res?.data?.feeWallet,
                        status: res?.data?.feeWallet?.status?.code
                    });
                    setPaginationConfig((prev) => ({
                        ...prev,
                        totalPages: res?.data?.totalCount,
                    }));
                }
            }
        },
        [paginationConfig?.page, params?.addressKey, debouncedSearch, sortConfig]
    )

    const tableHeaders = [
        { headerItem: "Date", sortValue: "updatedDate" },
        { headerItem: "Transaction Type" },
        { headerItem: "Transaction ID" },
        { headerItem: "Asset" },
        { headerItem: "Amount", sortValue: "amount" },
        { headerItem: "Previous Balance" },
        { headerItem: "Current Balance" },
    ];

    const tableWalletInfoHeaders = [
        { headerItem: "Token" },
        { headerItem: "Balance Amount" },
        { headerItem: "Last Updated" },
        { headerItem: "" },
    ];

    const handleSorting = (orderBy, sortBy) => {
        setSortConfig({ orderBy, sortBy });
    };

    useEffect(
        () => {
            fetchFeeWalletDetailsData();
        },
        [paginationConfig?.page, fetchFeeWalletDetailsData, debouncedSearch]
    );

    const navigateToBlockchain = () => {
        let url = getCryptoLink(
            feeWalletData?.feeWalletDetailObj?.network,
            feeWalletData?.feeWalletDetailObj?.crypto?.symbol,
            feeWalletData?.feeWalletDetailObj?.address?.key,
        );
        url === undefined && feeWalletData?.feeWalletDetailObj?.blockchain === "Litecoin" ? setToastMessage("Litecoin will work only for mainnet wallets") : window.open(url, "_blank");
    }
    const handleWalletStatusChange = (event) => {
        const updatedStatus = event?.target?.value;
        setFeeWalletData((prevState) => {
          return {
            ...prevState,
            status: updatedStatus,
          };
        });
        updateFeeWalletStatus(updatedStatus);
      };
      const updateFeeWalletStatus = useCallback(
        async (status) => {
          try {
            const res = await apiRequest("/update-fee-wallet-status", "POST", {
              addressKey: params?.addressKey,
              status: status, // Pass the updated status directly here
            });
            if (res?.success) {
              // Show a success toast message when status is updated
              setToastMessage("Wallet status updated successfully");
            } else {
              // Show an error toast if the API response isn't successful
              setToastMessage("Failed to update wallet status");
            }
          } catch (error) {
            setToastMessage("An error occurred while updating wallet status");
          }
        },
        [params?.addressKey]
      );
    return (
        <Box className="flex flex-col mb-4 gap-4">
            <Box>
                <Breadcrumbs
                    separator={
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/breadcrum_next.png`}
                            alt="dashboardIcon"
                            className="w-[18px] h-[18px]"
                        />
                    }
                    aria-label="breadcrumb"
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <Box className="border-[1px] rounded-lg w-full wallet-details">
                <div className="flex justify-between p-6">
                    <div>
                        <EditBalance
                            open={openEditBalance}
                            close={openEditBalanceModal}
                            currentBalance={balanceData?.balanceAmount}
                            cryptoSymbol={balanceData?.cryptoSymbol}
                            walletId={feeWalletData?.feeWalletDetailObj?._id}
                            newBalanceAmountUSDApprox={
                                feeWalletData?.balanceAmountUSDApprox}
                            fetchWalletDetailsData={fetchFeeWalletDetailsData}
                            path="/update-fee-wallet-crypto-balance"
                        />
                        <div className="flex items-center gap-4 font-semibold">
                            <p className="">Status:</p>
                            <DropDown
                                options={constant?.walletStatus}
                                value={feeWalletData?.status}
                                onChange={handleWalletStatusChange}
                            />
                        </div>
                        {params?.addressKey} <CopyButton textToCopy={params?.addressKey} />
                    </div>
                    <div className="flex gap-2 items-center">
                        <CommonButton text="View on blockchain" bgColor="black" onClick={isViewPermissionValid? undefined:navigateToBlockchain} disabled={isViewPermissionValid} />
                    </div>
                </div>
                <Box>
                    <CommonTable
                        headers={tableWalletInfoHeaders}
                        renderRow={(row) => renderWalletInfoRow(row, imageBaseURL, openEditBalanceModal)}
                        data={feeWalletData?.balance}
                        approxUSDBalance={feeWalletData?.balanceAmountUSDApprox}
                    />
                </Box>
                <Box className="flex  flex-col justify-between p-6">
                    <div>
                        <p className="font-semibold ">All Transactions</p>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex py-6">
                            <TextField
                                placeholder="Search transactions"
                                type="search"
                                size="small"
                                onChange={handleChangeSearchText}
                                value={debouncedSearch}
                                InputProps={{
                                    endAdornment: (
                                        <img
                                            src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                            alt="search"
                                            className="w-6 h-6"
                                        />
                                    ),
                                }}
                            />
                        </div>
                        <div className="items-center flex justify-center gap-2">
                            <Box onClick={isViewPermissionValid? undefined: () => onDownloadClick(feeWalletData?.balanceHistory, "Fee-Wallet-Table-Export")} className={`cursor-pointer ${isViewPermissionValid ? 'disabled' : ''}`}>
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/download_button.png`}
                                    alt="download-icon"
                                    className="w-10 h-10"
                                />
                            </Box>
                            <CommonButton
                                text="Filter"
                                bgColor="white"
                                StartIcon={
                                    <img
                                        src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                                        alt="filter"
                                        className="w-6 h-6"
                                    />
                                }
                            />
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/settings_button.png`}
                                alt="settings"
                                className="w-10 h-10"
                            />
                        </div>
                    </div>
                </Box>
                <Box>
                    <CommonTable
                        headers={tableHeaders}
                        data={feeWalletData?.balanceHistory}
                        renderRow={renderRow}
                        sorting={handleSorting}
                        setSortConfig={setSortConfig}
                        sortConfig={sortConfig}
                    />
                    <Snackbar
                        open={Boolean(toastMessage)}
                        autoHideDuration={3000}
                        onClose={handleCloseToast}
                        message={toastMessage}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    />
                </Box>
                <div className="mt-2 flex justify-between p-6 w-full">
                    <CommonPagination
                        totalPages={paginationConfig?.totalPages}
                        setPage={(newPage) => setPaginationConfig((prev) => ({ ...prev, page: newPage }))}
                        page={paginationConfig?.page}
                    />
                </div>
            </Box>
        </Box>
    );
};

const renderRow = (row) => {
    return (
        <>
            <TableCell align="left">
                {row?.updatedDate
                    ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
                    : "NA"}
            </TableCell>
            <TableCell align="left">
                {row?.transactionType ? row?.transactionType : "NA"}
            </TableCell>
            <TableCell align="left">
            <a
                href={`/transactions/${row.transactionId}`}  // URL to navigate to transaction details page
                target="_blank" // Opens the link in a new tab
                rel="noopener noreferrer" // Security measure
                className="text-blue-500 underline hover:text-blue-700"
            >
                {getShortenedTransactionId(row?.transactionId ? row?.transactionId : "NA")}
             </a>            
          </TableCell>
            <TableCell align="left">
                {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
            </TableCell>
            <TableCell align="left">
                {row?.amount ? row?.amount?.toFixed(6) : "0.0"}
            </TableCell>
            <TableCell align="left">
                {row?.previousBalance ? row?.previousBalance?.toFixed(6) : "0.0"}
            </TableCell>
            <TableCell align="left">
                {row?.currentBalance ? row?.currentBalance?.toFixed(6) : "0.0"}
            </TableCell>
        </>
    );
};

const renderWalletInfoRow = (row, imageBaseURL, openEditBalanceModal) => {
    const isViewPermissionValid = checkViewPermission();
    return (
        <>
            <TableCell>
                <Box className="flex items-center gap-2">
                    {getCryptoImage(row?.cryptoSymbol, imageBaseURL, 6)}
                    {row?.cryptoSymbol ? row?.cryptoSymbol : "NA"}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box className="flex gap-4">
                    {row?.balanceAmount ? row?.balanceAmount?.toFixed(6) : "0.0"}
                    <img
                        src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
                        alt="edit-icon"
                        onClick={isViewPermissionValid? undefined: () => openEditBalanceModal(row)}
                        disabled={isViewPermissionValid}
                        className="w-5 h-5"
                    />
                </Box>
            </TableCell>
            <TableCell align="left">
                {row?.updatedDate
                    ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
                    : "NA"}
            </TableCell>
        </>
    );
};
export default React.memo(FeeWalletDetails);

