import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/actions/commonAction.js";
import { axiosInstance } from "../../utils/api.js";

const AxiosInterceptor = (props) => {
  const reduxDispatch = useDispatch();
  const pendingRequests = useRef(0); // Track pending requests using a ref

  const { onNetworkError } = props;

  useEffect(() => {
    // Request interceptor to show loading
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        pendingRequests.current += 1; // Increment the counter when a new request is made
        reduxDispatch(setLoading(true)); // Show loading spinner
        return config;
      },
      (error) => {
        pendingRequests.current -= 1; // Decrement on error
        reduxDispatch(setLoading(false)); // Stop loading on error
        return Promise.reject(error);
      }
    );

    // Response interceptor to handle success and error responses
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        pendingRequests.current -= 1; // Decrement counter on successful response

        // If all requests are done, stop loading
        if (pendingRequests.current === 0) {
          reduxDispatch(setLoading(false)); // Hide loader once all requests are done
        }
        return response;
      },
      (error) => {
        pendingRequests.current -= 1; // Decrement counter on error

        // Stop loading if all requests are completed
        if (pendingRequests.current === 0) {
          reduxDispatch(setLoading(false)); // Hide loader once all requests are done
        }

        // Handle network error
        if (!error?.response) {
          onNetworkError(); // Call the function to handle network errors
        }

        return Promise.reject(error);
      }
    );

    // Cleanup interceptors on component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [reduxDispatch, onNetworkError]); // Re-run the effect if onNetworkError changes

  return null; // This component doesn't render anything
};

export default AxiosInterceptor;
