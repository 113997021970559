import React from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../components/common/CommonButton.js";

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate("/");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div className="text-center p-8 max-w-lg w-full bg-white rounded-lg shadow-2xl">
        <h1 className="text-4xl font-extrabold text-gray-800">
          Oops! Page Not Found
        </h1>
        <p className="mt-4 text-lg text-gray-500">
          We couldn't find the page you're looking for. It might have been moved or deleted. Don't worry, you can easily return to the dashboard or try again.
        </p>
        <div className="mt-6 flex justify-center">
          <CommonButton
            className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
            text="Go Back to Dashboard"
            type="button"
            bgColor="black"
            onClick={navigateToDashboard}
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
