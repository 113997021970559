import React from "react";
import CommonButton from "../components/common/CommonButton.js";

const NetworkError = (props) => {
  const { onClick, disableReload, showReload } = props;

  return (
    <div className="fixed bottom-4 right-4 p-4 rounded-lg z-50 bg-white shadow-lg w-98 max-w-full sm:w-80 md:w-96">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="flex-1">
          <h6 className="text-red-500 font-semibold leading-relaxed">
            Looks like there is some network error. Please reload the page!
          </h6>
        </div>
      </div>
      {showReload && (
        <div className="flex justify-end mt-1">
          <CommonButton
            className={`px-6 py-2 bg-blue-500 text-white rounded-lg ${
              disableReload
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300"
            }`}
            disabled={!!disableReload}
            text="Reload"
            type="button"
            bgColor="black"
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default NetworkError;